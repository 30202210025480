<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <!-- <h6 class="text-primary">الإجراءات</h6> -->
        <div v-html="item.content"></div>
      </b-card>
    </b-col>
    <b-col cols="12">
      <div class="d-flex">
        <h6 class="text-primary mx-1">
          التكلفة: <span class="text-secondary">{{item.cost.toLocaleString("en-US")}} ل.س</span>
        </h6>
        <h6 class="text-primary">
          المدة المتوقعة: <span class="text-secondary">{{ item.expected_duration }}</span>
        </h6>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { BCard, BCol, BRow } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCol,
    BRow,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style >
</style>