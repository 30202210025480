<template>
  <div class="dropdown-data">
    <app-collapse accordion :type="collapseType">
      <div>
        <div v-if="loading" class="mt-1">
          <div
            class="
              w-100
              d-flex
              justify-content-between
              pt-1
              px-1
              mb-1
              rounded-sm
              border
            "
            v-for="n in 8"
            :key="n"
          >
            <b-skeleton animation="wave" height="25px" width="25%"></b-skeleton>
            <div class="d-flex">
              <b-skeleton
                animation="wave"
                class="mx-1"
                height="25px"
                width="25px"
              ></b-skeleton>
              <b-skeleton
                animation="wave"
                height="25px"
                width="25px"
              ></b-skeleton>
            </div>
          </div>
        </div>
        <app-collapse-item
          v-else
          v-for="(item, i) in options"
          :key="i"
          :title="item.name_ar || item.title || item.name"
          class="border rounded my-1"
        >
          <template v-for="(index, name) in $scopedSlots" v-slot:[name]>
            <slot :name="name" v-bind="item"></slot>
          </template>
          <slot name="detals" v-bind="item"></slot>
        </app-collapse-item>
      </div>
    </app-collapse>

    <div v-if="!loading && options.length==0" class="mb-0 w-100 my-2 bg-light-primary rounded-sm shadow-sm">
      <div class="alert-body p-1">
        <feather-icon icon="InfoIcon" size="16" class="mx-50" />
        <span>عذراً لا يوجد نتائج لعرضها</span>
      </div>
      <div class="w-100 rounded-sm alert-dropdown"></div>
    </div>

    <Pagination
      v-model="currentPage"
      align="center"
      first-number
      last-number
      :total-rows="total_row"
      :per-page="per_page"
      class="mb-0"
    >
    </Pagination>
  </div>
</template>
<script>
import { BCard, BSkeleton, BAlert } from "bootstrap-vue";
import Pagination from "@/components/pagination/index.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import store from "./store";
import { debounce } from "lodash";

export default {
  props: {
    ep: { type: String, default: () => null },
    fields: { type: Array, default: () => [] },
    pagination: { type: Boolean, default: () => true },
    dataPath: {
      type: String,
      default: () => "data",
    },
    id: {
      type: String,
      default: () => "drobdown",
    },
    collapseType: {
      type: String,
      default: () => "border",
    },
    per_page: {
      type: Number,
      default: () => 10,
    },
    filter: {
      type: String,
      default: () => "",
    },
  },
  components: {
    BCard,
    BSkeleton,
    AppCollapse,
    AppCollapseItem,
    Pagination,
    BAlert,
  },
  data() {
    return {
      list: [],
      currentPage: 1,
    };
  },
  computed: {
    options() {
      return this.$store.getters[`${this.id}/options`] || this.$attrs.options;
    },
    loading() {
      return this.$store.getters[`${this.id}/loading`];
    },
    total_row() {
      return this.$store.getters[`${this.id}/total_row`];
    },
  },
  methods: {
    refreshOptions(val) {
      if (this.$store.hasModule(this.id)) {
        this.$store.dispatch(`${this.id}/fetchOptions`, {
          ep: "" || this.ep,
          path: this.dataPath,
          utils: {
            page: this.currentPage,
            filter: this.filter,
            ...val,
          },
        });
      }
    },
  },
  watch: {
    currentPage(val) {
      this.refreshOptions();
    },
    filter: debounce(function (value) {
      this.refreshOptions();
    }, 700),
  },
  created() {
    if (this.ep && !this.$attrs.options && !this.$store.hasModule(this.id)) {
      this.$store.registerModule(this.id, store);
      if (this.ep.length > 1) {
        this.$store.dispatch(`${this.id}/fetchOptions`, {
          ep: this.ep,
          path: this.dataPath,
          utils: {
            page: this.currentPage,
            filter: this.filter,
          },
        });
      }
    }
  },
  beforeDestroy() {
    if (this.$store.hasModule(this.id)) {
      this.$store.unregisterModule(this.id);
    }
  },
};
</script>

<style lang="scss" >
.dropdown-data {
  .card .card-header {
    padding-top: 0.6rem;
    padding-bottom: 00.6rem;
  }
  .alert-dropdown {
    height: 5px;
    background:#d0a15350;
  }
}
</style>