<template>
  <b-pagination
    size="lg"
    v-bind="$attrs"
    v-on="$listeners"
    :total-rows="$attrs['total-rows'] || 5"
    :per-page="$attrs['per-page'] || 10"
    first-number
    last-number
    v-model="currentPage"
  >
    <template #prev-text>
      <feather-icon icon="ChevronRightIcon" size="21" />
    </template>
    <template #next-text>
      <feather-icon icon="ChevronLeftIcon" size="21" />
    </template>
  </b-pagination>
</template>
<script>
import { BPagination, BSpinner } from "bootstrap-vue";
export default {
  components: {
    BPagination,
    BSpinner,
  },
  props: {
    value: {
      type: Number,
      default: () => "",
    },
  },
  computed: {
    currentPage: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>
