import axios from "@axios";
import { get } from "lodash";
export default {
  namespaced: true,
  state: () => ({
    loading: false,
    options: [],
    total_row: 1,
  }),
  getters: {
    loading(state) {
      return state.loading;
    },
    options(state) {
      return state.options;
    },
    total_row(state){
      return state.total_row
    }
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },

    setOptions(state, payload) {
      state.options = payload;
    },
  },
  actions: {
    async fetchOptions(
      { commit, state },
      payload = { ep: "", qp: {}, path: "data",utils:{} }
    ) {
      commit("setLoading", true);
      try {
        const data = await axios
          .get(payload.ep, {params: payload.utils})
          .then((res) => res.data);
        const resolved = get(data, payload.path);
        commit("setOptions", resolved);
        state.total_row = data.pagination.total_row;
      } catch (err) {
        console.error(err);
      } finally {
        commit("setLoading", false);
      }
    },
  },
};
